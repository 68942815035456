import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CurrentUserController } from '../user/currentUser.controller';
import { INgsPermissionService } from '@syspons/ngs-permission';
import { NgsCorePermissionsType, NgsVersioningPermissions } from '@syspons/models';

@UntilDestroy()
@Injectable()
export class PermissionServiceImpl extends INgsPermissionService {
  constructor(private currentUserController: CurrentUserController) {
    super();
  }

  can(permissionName: NgsCorePermissionsType, permissionRoute?: string, anyPermission?: boolean): Observable<boolean> {
    if (this.currentUserController.userRoles$ && this.currentUserController.userRoles$.value != null) {
      const roles = this.currentUserController.userRoles$.value;
      const res: Array<string> = [];
      roles.forEach(role => {
        const permission = role.get('permissions')?.params.objects.find(o => {
          const preview: any = o.preview;
          const permissionString = permissionRoute != null ? `${permissionRoute}.${permissionName}` : permissionName;
          return (preview.route || o.defaultAttribute) === permissionString;
        });
        if (permission) {
          res.push(permission.id);
          return;
        }
      });
      return of(res && res.length > 0 ? true : false);
    } else {
      // User is missing Role
      return of(false);
    }
  }
}
