import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

import { NgsOptionsService } from '@syspons/ngs-storage';
import { LOCALE_OPTION_KEY } from '@syspons/ngs-locale';
import { LocaleConfig, MonitoringSchemaConfig } from '@syspons/monitoring-api';

import ParseSystemService from '../parseSystem/parseSystem.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ParseTranslationService {
  constructor(
    private systemService: ParseSystemService,
    private t: TranslateService,
    private options: NgsOptionsService,
  ) {
    injectUserTranslations(systemService, t, options, this);
  }
}

export const injectUserTranslations = (
  systemService: ParseSystemService,
  t: TranslateService,
  options: NgsOptionsService,
  serviceRef: ParseTranslationService | ParseSystemService,
) => {
  combineLatest([systemService.systemObjFetched$, options.getObservable(LOCALE_OPTION_KEY)])
    .pipe(untilDestroyed(serviceRef))
    .subscribe(([systemObjFetched, lang]) => {
      if (systemObjFetched) {
        const localConfig: LocaleConfig = systemService.systemObj.get('local_config');
        localConfig.languages.forEach((lang: string) => {
          t.setTranslation(
            lang,
            systemService.systemObj.get((MonitoringSchemaConfig.locale.localeAttrPrefix + lang) as any),
          );
        });
        t.setDefaultLang(localConfig.defaultLanguage);
        t.use((lang as string) || 'en');
        switch (lang) {
          case 'de':
            registerLocaleData(localeDe, 'de', { data: 'blabla' });
            break;
          default:
            registerLocaleData(localeEn, 'en', { data: 'blabla' });
            break;
        }
      }
    });
};
