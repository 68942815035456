import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import Parse from 'parse';

import {
  MonitoringCloudFunctions,
  MonitoringSchemaConfig,
  CloudFunctionResult,
  CloudFile,
  LiveSocketObject,
  CloudFunctionResultCompleteActions,
  CloudFunctionResultClientActions,
  CloudFunctionResultAction,
} from '@syspons/monitoring-api';
import { NgsDialogService } from '@syspons/ngs-dialog';
import { NgsSnackbarService } from '@syspons/ngs-snackbar';
import { ReplaySubject } from 'rxjs';
import { NgsLoaderService } from '@syspons/ngs-loader';
import ParseLiveSocketService from '../parseLiveSocket/parseLiveSocket.service';
import { NgsDialogData } from '@syspons/models';
import mime from 'mime';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ParseCloudController {
  constructor(
    private ngsDialogService: NgsDialogService,
    private ngsSnackbarService: NgsSnackbarService,
    private ngsLoaderService: NgsLoaderService,
    private liveSocketService: ParseLiveSocketService,
  ) {}

  runCloudFunction<T, R>(funcName: MonitoringCloudFunctions, params: T): Promise<CloudFunctionResult<R>> {
    return new Promise((resolve, reject) => {
      this.ngsLoaderService.isLoading$.next(true);
      Parse.Cloud.run(funcName, params as any).then(
        res => {
          this.ngsLoaderService.isLoading$.next(false);
          this.onCloudFunctionCompleted(res).then(clientRes => {
            resolve(clientRes || res);
          }, reject);
        },
        e => {
          this.onCloudFunctionError(e, reject);
        },
      );
    });
  }

  deleteFile(objectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      new Parse.Query(MonitoringSchemaConfig.files.className)
        .equalTo('objectId', objectId)
        .first()
        .then(object => {
          if (object) {
            object.destroy().then(() => resolve(), reject);
          } else {
            reject('File not found');
          }
        }, reject);
    });
  }

  downloadFile(objectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      new Parse.Query(MonitoringSchemaConfig.files.className)
        .equalTo('objectId', objectId)
        .first()
        .then(object => {
          if (object) {
            const file = object.get('file');
            const name = object.get('name');
            return file.getData().then((data: string) => {
              const link = document.createElement('a');
              link.setAttribute('type', 'hidden');
              link.setAttribute('target', '_blank');
              const mimetype = mime.getType(file.name());
              link.href = `data:${mimetype};base64,${data}`;
              link.download = name;
              document.body.appendChild(link);
              link.click();
              link.remove();
              resolve();
            }, reject);
          } else {
            reject('File not found');
          }
        }, reject);
    });
  }

  uploadParseFiles(config?: { multiple?: boolean; accept?: string }): Promise<CloudFile[]> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('multiple', config?.multiple ? 'true' : 'false');
      if (config?.accept) {
        input.setAttribute('accept', config.accept);
      }
      input.addEventListener('change', e => {
        if (input.files && input.files.length > 0) {
          this.ngsLoaderService.isLoading$.next(true);
          const promises: Promise<CloudFile>[] = [];
          for (let i = 0; i < input.files.length; i++) {
            promises.push(this.saveParseFile(input.files[i]));
          }
          Promise.all(promises).then(files => resolve(files), reject);
        }
        input.remove();
      });
      document.body.appendChild(input);
      input.click();
    });
  }

  private saveParseFile(file: File): Promise<CloudFile> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const originalName = file.name;
          const filename = file.name.replace(/[^a-zA-Z0-9 _-](?=.*\.)/g, '');
          const parseFile = new Parse.File(filename, { base64: reader.result as string });
          parseFile.save().then(savedFile => {
            const obj = new Parse.Object(MonitoringSchemaConfig.files.className);
            obj.set('name', originalName);
            obj.set('file', savedFile);
            obj.save().then(res => {
              resolve({
                id: res.id,
                name: originalName,
                url: res.get('file').url(),
              });
            }, reject);
          }, reject);
        } else {
          reject('Error encoding 64 string of file:' + file.name);
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  private onCloudFunctionCompleted = (result: CloudFunctionResult<any>): Promise<any> => {
    return new Promise((resolve, reject) => {
      const openLiveDialog = (
        cloudFunctionResult: CloudFunctionResult<Parse.Object<LiveSocketObject<NgsDialogData>>>,
        action: CloudFunctionResultAction,
      ) => {
        if (cloudFunctionResult.result) {
          const data$ = new ReplaySubject<NgsDialogData>();
          this.liveSocketService
            .performQuery(
              cloudFunctionResult.result.id,
              cloudFunctionResult.result.get ? cloudFunctionResult.result.get('name') : undefined,
            )
            .subscribe(socketObject => {
              data$.next({ ...(socketObject.get('data') as NgsDialogData) });
            });
          this.ngsDialogService.openInputsDialog(data$).subscribe((value: any) => {
            action.onClientFunctionComplete.forEach(onClientFunctionComplete => {
              switch (onClientFunctionComplete) {
                case CloudFunctionResultClientActions.delete_socket_object:
                  cloudFunctionResult.result.destroy();
                  break;
              }
            });
            resolve(result);
          });
        }
      };

      if (result.actions && result.actions.length > 0) {
        result.actions.forEach(action => {
          action.onCloudFunctionComplete.forEach(onCloudCompleteAction => {
            switch (onCloudCompleteAction) {
              case CloudFunctionResultCompleteActions.open_live_dialog:
                openLiveDialog(result, action);
                break;
            }
          });
        });
      } else {
        resolve(null);
      }
    });
  };

  private onCloudFunctionError = (
    error: Parse.Error,
    reject?: ((reason?: any) => void) | null,
    toastDuration?: number,
  ) => {
    this.ngsLoaderService.isLoading$.next(false);
    console.error(error);
    let args = {};
    if (error.message.indexOf('<$args>') >= 0) {
      // Parse error string args
      args = JSON.parse(
        error.message.substring(error.message.indexOf('<$args>') + 7, error.message.indexOf('</$args>')),
      );
      error.message = error.message.substring(0, error.message.indexOf('<$args>'));
    }

    this.ngsSnackbarService.open(error.message || JSON.stringify(error), undefined, { duration: toastDuration });
    if (reject) {
      reject({ ...error, args });
    }
  };
}
